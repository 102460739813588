// COMPONENT : Feedback block
////////////////////////////////////////////////////////////
(function() {

  function makeRequest(element, event) {

    if(event.target && event.target.matches('.btnYes')) {
      action_name = 'increase_like';
    }
    else if (event.target && event.target.matches('.btnNo')) {
      action_name = 'increase_dislike';
    }

    var ajaxParams = {
      action: action_name,
      post_id: ajax.post_id,
      log_ip: ajax.log_ip
    }

     $.ajax({
          url: ajax.url,
          method: 'GET',
          data: ajaxParams,
          success: function(response, status){
            $("#feedback--question").toggleClass("hidden");
            $("#feedback--success").toggleClass("hidden");
          }
      });
  }

  var feedback = document.getElementById("feedback");

  if(null !== feedback) {
    feedback.addEventListener('click', function(event) {
      if(event.target && event.target.matches('.js-feedback-btn')) {
        makeRequest(this, event);
      }
    });
  }

})();
