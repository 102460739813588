var LoadMore = {
	init: function() {
        $('.js-load-more').each(function (index, value) {
            var button        = $(this);
            var locked        = false;
            var wrapper       = $('#' + button.data('load-more-wrapper'));
            var query         = load_more.queries[wrapper.data('load-more-query-id')];
            var currentPage   = query.queryVars.paged;
            var ajaxParams  = {
                action: 'get_ajax_content',
                template: wrapper.data('load-more-template'),
            };
            if( typeof(currentPage) == "undefined" || currentPage == 0 || currentPage === null ) {
                currentPage = 1;
            }
            button.click(function() {
                if (! locked && currentPage < query.maxPages) {
                    locked = true;
                    var data = ajaxParams;
                    $.extend(data,query.queryVars);
                    data.paged = ++currentPage;
                    var success = function( response, status ) {
                        if( "success" == status && response.length && '0' != response) {
                            $(response).hide().appendTo(wrapper).fadeIn(200);
                        }
                        if(currentPage >= query.maxPages) {
                            button.remove();
                        } else {
                            locked = false;
                        }
                    }
                    var error = function( jqXHR, status, error ) {
                        locked = false;
                    }
                    $.ajax({
                        url: load_more.ajax_url,
                        method: "GET",
                        data: data,
                        success : success,
                        error: error
                    });
                }
            });
        });
    }
};
