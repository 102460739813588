// COMPONENT
//
// The building blocks for our Guide component.
////////////////////////////////////////////////////////////
(function($) {

  var headerHeight = $('.headerMain').outerHeight();

  var panels = $('.js-panel');
  panels.not(':first').hide();
  panels.each(function(index) {
    var panel = $(this);
    var answer = panel.find('.collapse');

    // show next question
    var next = panel.find('.js-next');
    next.on('click', function() {
      answer.collapse('hide');
      next.hide();
      var nextPanel = panels.eq(index+1);
      if (nextPanel) {
        nextPanel.fadeIn(150);
      }
    });

    // open/close answer
    var open = panel.find('.js-open');
    var close = panel.find('.js-close');
    open.on('click', function() {
      answer.collapse('show');
      open.addClass('active');
      close.removeClass('active');
      $('body').stop().animate({scrollTop:panel.offset().top-headerHeight}, 150);
    });
    close.on('click', function() {
      answer.collapse('hide');
      open.removeClass('active');
      close.addClass('active');
      next.trigger('click');
    });

    // reset all
    var reset = panel.find('.js-reset');
    reset.on('click', function() {
      panels.not(':first').slideUp(150, function() {
        panels.find('.js-next').show();
        panels.find('.js-open, .js-close').removeClass('active');
      });
    });

  });

})(jQuery);
